import axios from 'axios';
import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { checkAuthenticity } from '../../util/auth';
import { BACKEND_URL } from '../../util/config';

/* Primeira página, contendo cards com estatísticas básicas, 
  com quantidades e gráficos.
*/
export const DashboardHelper = () => {
  const [cookie, setCookie] = useCookies(['auth']);
  const [quantidadeFuncionarios, setQuantidadeFuncionarios] = useState(0);
  const [quantidadeAgentes, setQuantidadeAgentes] = useState(0);
  const [quantidadeSupervisores, setQuantidadeSupervisores] = useState(0);
  const [quantidadeAdministradores, setQuantidadeAdministradores] = useState(0);

  const [empresas, setEmpresas] = useState([
    'Princesa Negócios Imobiliários Ltda',
    'Conexão Brasil Norte Serviços Administrativos Ltda',
    'Conexão Brasil Serviços Administrativos Ltda',
    'Conexão Nordeste Serviços Administrativos Ltda',
    'Brasil Mais Forte',
  ]);

  const [sede, setSede] = useState({
    descricao: "Rua Conselheiro Franco, 292 - sala 302 – Shopping Jomafa CEP 44002-128 – CENTRO - FEIRA DE SANTANA – BA",
    link: "https://maps.app.goo.gl/fTfWFrBbwfPcfwia8"
  });

  const [contatos, setContato] = useState([
    {
      type: "Telefone fixo",
      list: [
        {
          content: "(75) 3602 9019",
          link: "tel:7536029019"
        },
        {
          content: "(75) 3512 0698",
          link: "tel:7535120698"
        }
      ]
    },
    {
      type: "Site",
      list: [
        {
          content: "https://www.grupoconexaobr.com/",
          link: "https://www.grupoconexaobr.com/"
        }
      ]
    },
    {
      type: "Instagram",
      list: [
        {
          content: "@conexaobrasil.cb",
          link: "https://www.instagram.com/conexaobrasil.cb"
        },
      ]
    },
    // {
    //   type: "Facebook",
    //   list: [
    //     {
    //       content: "Facebook.com",
    //       link: "https://www.facebook.com/conexaobrasil"
    //     },
    //   ]
    // }
  ]);

  const [extras, setExtras] = useState([
    {
      name: "Portais",
      link: "/learning/portais"
    },
    {
      name: "Políticas e Manuais",
      link: "/learning/governanca"
    },
    {
      name: "Treinamentos",
      link: "/learning/treinamento"
    },
    {
      name: "Provas",
      link: "/learning/provas"
    }
  ]);

  const [diretores, setDiretores] = useState([
    {
      rule: "Diretor Executivo",
      name: "Onofre Moreira Coelho",
      phones: [
        {
          content: "(75) 98811 1964",
          link: "tel:75988111964"
        },
        {
          content: "(75) 99833 6374",
          link: "tel:75998336374"
        }
      ],
      email: {
        content: "onofremoreira@princesafsa.com.br",
        link: "mailto:onofremoreira@princesafsa.com.br"
      }
    },
    {
      rule: "Diretor Financeiro",
      name: "Gideão Mendonça Nogueira",
      phones: [
        {
          content: "(71) 99219 3966",
          link: "tel:71992193966"
        }
      ],
      email: {
        content: "financeirossa@princesafsa.com.br",
        link: "mailto:financeirossa@princesafsa.com.br"
      }
    },
    {
      rule: "Diretor Comercial",
      name: "Nadson Souza Queiroz",
      phones: [
        {
          content: "(75) 98139 4960",
          link: "tel:75981394960"
        }
      ],
      email: {
        content: "nadson@princesafsa.com.br",
        link: "mailto:nadson@princesafsa.com.br"
      }
    },
    {
      rule: "Coordenador de Consórcios",
      name: "Erick Silva dos Santos",
      phones: [
        {
          content: "(75) 98104 6957",
          link: "tel:75981046957"
        }
      ],
      email: {
        content: "erick@princesafsa.com.br",
        link: "mailto:erick@princesafsa.com.br"
      }
    }
  ]);

  const [suportes, setSuportes] = useState([
    {
      rule: "Suporte Crédito",
      name: "Larissa Kelvin",
      phones: [
        {
          content: "(75) 98137 6348",
          link: "tel:75981376348"
        }
      ],
      email: {
        content: "suporte01@princesafsa.com.br",
        link: "mailto:suporte01@princesafsa.com.br"
      }
    },
    {
      rule: "Suporte Consórcio",
      name: "Ana Cláudia",
      phones: [
        {
          content: "(75) 98105 5652",
          link: "tel:75981055652"
        }
      ],
      email: {
        content: "suporte02@princesafsa.com.br",
        link: "mailto:suporte02@princesafsa.com.br"
      }
    },
  ]);

  /* Requisição para buscar a quantidade de funcionários. */
  const getQuantidadeFuncionariosTotal = useCallback(async () => {
    await axios
      .get(BACKEND_URL + "funcionario/count/all",
        {
          headers: {
            Authorization: ["Bearer", cookie.access_token].join(" "),
          },
        })
      .then(response => {
        setQuantidadeFuncionarios(response.data.count_funcionarios);
        setQuantidadeAdministradores(response.data.count_administradores);
        setQuantidadeSupervisores(response.data.count_supervisores);
        setQuantidadeAgentes(response.data.count_agentes);
      })
      .catch(erro => {
        console.log(erro);
        checkAuthenticity(erro.response.status);
      })
  }, [cookie]);

  return { getQuantidadeFuncionariosTotal, cookie, empresas, sede, contatos, extras, diretores, suportes, quantidadeFuncionarios, quantidadeAgentes, quantidadeSupervisores, quantidadeAdministradores }
}
