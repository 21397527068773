import { useState } from 'react';
import { error } from '../../util/alert';
import axios from 'axios';
import { BACKEND_URL } from '../../util/config';
import { filterCharacters } from '../../util/filter';
import Swal from 'sweetalert2';

export const CheckCredentialsHelper = () => {
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [cpfError, setCpfError] = useState(false);
  const [cpfErrorMessage, setCpfErrorMessage] = useState('');

  const validateInputs = (cpf, email) => {
    let isValid = true;

    if (!email || email.length < 11) {
      setEmailError(true);
      setEmailErrorMessage('Usuário inválido');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!cpf || cpf.length < 11) {
      setCpfError(true);
      setCpfErrorMessage('Senha deve conter pelo menos 6 caracteres');
      isValid = false;
    } else {
      setCpfError(false);
      setCpfErrorMessage('');
    }

    return isValid;
  };

  const checkCredentials = (e, handleClose) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    let email = data.get('email')
    let cpf = data.get('cpf')

    if (validateInputs()) {
      //monta o json para envio dos dados
      var jsonData = {
        cpf: filterCharacters(cpf),
        email: email,
      };

      //loga o usuário no sistema
      axios
        .post(BACKEND_URL + "user/esqueci-senha", jsonData)
        .then(response => {
          Swal.fire({
            title: 'Sucesso',
            text: 'Acesse o email para redefinir sua senha.',
            icon: 'success',
            confirmButtonText: 'Fechar',
          }).then((result) => {
            if (result.isConfirmed)
              handleClose();
          })
        })
        .catch(erro => {
          console.log(erro);
          if (erro.response.status === 401)
            error('Credenciais inválidas', 'Credenciais incorreta(s) ou não cadastradas.');
          else
            error('Erro desconhecido', 'Aconteceu algo inesperado, tente novamente. Caso o erro persista, entre em contato com o suporte.');
        });
    }
  }

  return {
    checkCredentials, emailError, emailErrorMessage, cpfError, cpfErrorMessage
  }
}
