import { useState } from 'react';

import axios from 'axios';
import { useCookies } from 'react-cookie';
import { BACKEND_URL } from '../../util/config';
import { filterCharacters } from '../../util/filter';
import Swal from 'sweetalert2';
import { error } from '../../util/alert';
import { useNavigate } from 'react-router-dom';

export const LoginHelper = () => {
  const [cookies, setCookie] = useCookies(['auth']);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const login = (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    let username = data.get('username')
    let password = data.get('password')

    //monta o json para envio dos dados
    var jsonData = {
      cpf: filterCharacters(username),
      senha: password,
    };

    Swal.fire({
      title: 'Entrando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      }
    })
    //loga o usuário no sistema
    axios
      .post(BACKEND_URL + "user/login", jsonData)
      .then(response => {
        //define um tempo de 60 minutos
        var now = new Date();
        now.setMinutes(now.getMinutes() + 60);

        //cria um cookie para armazenar o token JWT com o tempo de expiração definido acima
        setCookie('access_token', response.data.access_token, { expires: now, path: '/', secure: true, sameSite: 'Lax' });
        setCookie('tipo', response.data.tipo, { path: '/', secure: true, sameSite: 'Lax' });
        setCookie('reAuth', now, { expires: now, path: '/', secure: true, sameSite: 'Lax' });
        setCookie('nome', response.data.nome, { path: '/', secure: true, sameSite: 'Lax' });
        setCookie('id', response.data.id, { path: '/', secure: true, sameSite: 'Lax' })

        //redireciona o usuário para a página principal
        navigate('/');

        Swal.close();
      })
      .catch(erro => {
        if (erro.response.status === 401)
          error('Credenciais inválidas', 'Credenciais incorreta(s) ou não cadastradas.');
        else
          error('Erro desconhecido', 'Aconteceu algo inesperado, tente novamente. Caso o erro persista, entre em contato com o suporte.');
      });
  }

  return {
    handleClickOpen, handleClose, open, login,
  }
}
