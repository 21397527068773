import { useEffect } from 'react';
import { CountCard } from '../../components/CountCard';
import { DashboardHelper } from './helper';
import { CardInfo } from '../../components/CardInfo';
import { Divider, List, ListItemText, Typography, Grid2, Box, Link } from '@mui/material';
import { linkHoverCardStyle, textCardStyle } from '../../styles/styles';

/* Primeira página, contendo cards com estatísticas básicas, 
  com quantidades e gráficos.
*/
export const Dashboard = () => {
  const { getQuantidadeFuncionariosTotal, cookie, empresas, sede, contatos, extras, diretores, suportes, quantidadeFuncionarios, quantidadeAgentes, quantidadeSupervisores, quantidadeAdministradores } = DashboardHelper();

  useEffect(() => {
    if (cookie.tipo !== 'agente') {
      getQuantidadeFuncionariosTotal();
    }
  }, [getQuantidadeFuncionariosTotal]);

  return (
    <Box sx={{ paddingBottom: 2 }}>
      <Typography variant='h2' sx={textCardStyle}>
        Olá, {cookie.nome}
      </Typography>
      <Divider flexItem sx={{ marginY: 2 }} />
      {cookie.tipo !== 'agente' &&
        <Grid2 container spacing={1}>
          <CountCard quantity={quantidadeFuncionarios} text='Funcionários' />
          <CountCard quantity={quantidadeAgentes} text='Agentes' />
          <CountCard quantity={quantidadeSupervisores} text='Supervisores' />
          <CountCard quantity={quantidadeAdministradores} text='Administradores' />
        </Grid2>
      }
      <Grid2 container spacing={2} marginY={6} sx={{ textAlign: 'start' }}>
        <CardInfo title="Empresas do Grupo Conexão Brasil">
          <List>
            {empresas.map((empresa, index) =>
              <ListItemText key={index}
                sx={textCardStyle}
              >
                {empresa}
                <Divider flexItem />
              </ListItemText>
            )}
          </List>
        </CardInfo>
        <CardInfo title="Contato">
          <List>
            <ListItemText sx={textCardStyle}> Sede: <Link underline="hover" href={sede.link} sx={linkHoverCardStyle} rel="noreferrer" target="_blank">{sede.descricao}</Link>
              <Divider flexItem />
            </ListItemText>
            {contatos.map((contato, index) =>
              <ListItemText key={index} sx={textCardStyle}>{contato.type}: {contato.list.map((item, index) =>
                <Link underline="hover" key={index} href={item.link} sx={linkHoverCardStyle} rel="noreferrer" target="_blank">{index === 1 ? ' / ' : ''}{item.content}</Link>
              )}
                <Divider flexItem />
              </ListItemText>
            )}
          </List>
        </CardInfo>
        <CardInfo title='Acessos úteis'>
          <List>
            {extras.map((extra, index) =>
              <ListItemText key={index}>
                <Link underline="hover" href={extra.link} sx={linkHoverCardStyle}>{extra.name}</Link>
                <Divider flexItem />
              </ListItemText>
            )}
          </List>
        </CardInfo>
      </Grid2>
      <Box component='section'>
        <Typography variant='h2' sx={textCardStyle}>Diretores</Typography>
      </Box>
      <Grid2 container spacing={2} marginY={2} sx={{ textAlign: 'start' }}>
        {diretores.map((diretor, index) =>
          <CardInfo title={diretor.rule} key={index}>
            <Typography sx={textCardStyle}><strong>{diretor.name}</strong></Typography>
            <List>
              {diretor.phones.map((phone, index) =>
                <ListItemText key={index}>
                  <Link underline="hover" href={phone.link} sx={linkHoverCardStyle} rel="noreferrer" target="_blank">{phone.content}</Link>
                  <Divider flexItem />
                </ListItemText>
              )}
              <ListItemText>
                <Link underline="hover" href={diretor.email.link} sx={linkHoverCardStyle} rel="noreferrer" target="_blank">{diretor.email.content}</Link>
                <Divider flexItem />
              </ListItemText>
            </List>
          </CardInfo>
        )}
      </Grid2>
      <Box component='section'>
        <Typography variant='h2' sx={textCardStyle}>Suporte Operacional aos Consultores</Typography>
      </Box>
      <Grid2 container spacing={2} marginY={2} sx={{ textAlign: 'start' }}>
        {suportes.map((suporte, index) =>
          <CardInfo title={suporte.rule} key={index}>
            <Typography sx={textCardStyle}><strong>{suporte.name}</strong></Typography>
            <List>
              {suporte.phones.map((phone, index) =>
                <ListItemText key={index}>
                  <Link underline="hover" href={phone.link} sx={linkHoverCardStyle} rel="noreferrer" target="_blank">{phone.content}</Link>
                  <Divider flexItem />

                </ListItemText>
              )}
              <ListItemText>
                <Link underline="hover" href={suporte.email.link} sx={linkHoverCardStyle} rel="noreferrer" target="_blank">{suporte.email.content}</Link>
                <Divider flexItem />
              </ListItemText>
            </List>
          </CardInfo>
        )}
      </Grid2>
    </Box>
  );
}
