import { useEffect } from 'react';
import { SectionLearning } from '../../components/SectionLearning';
import { LearningHelper } from './helper';

export const Learning = () => {
  const { section, portais, pilarGovernanca, pilarLGPD, pilarRelacionamentoCliente, pilarTecnologia, pilarTreinamento, provas } = LearningHelper();

  useEffect(() => {
    if (section["*"])
      document.getElementById(`#${section["*"]}`).scrollIntoView();
  }, [section]);

  return (
    <div className="">
      <SectionLearning id="#portais" title='Portais' list={portais} />
      <SectionLearning id="#governanca" title='Pilar Governança' list={pilarGovernanca} />
      <SectionLearning id="#lgpd" title='Pilar LGPD' list={pilarLGPD} />
      <SectionLearning id="#cliente" title='Pilar Relacionamento com o cliente' list={pilarRelacionamentoCliente} />
      <SectionLearning id="#tecnologia" title='Pilar Tecnologia' list={pilarTecnologia} />
      <SectionLearning id="#treinamento" title='Pilar Treinamento' list={pilarTreinamento} />
      <SectionLearning id="#provas" title='Provas' list={provas} />
    </div>
  );
}
